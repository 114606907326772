import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Typography, OutlinedInput, FormControl } from '@material-ui/core';
import { directusProject } from 'src/services/directus';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MaskedInput from "react-text-mask";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

let company = [];

const LoginSMSEmail = (props) => {

  function BackButton() {
    props.setMode(1);
  }

  function ChooseCompanyDialog(props) {
    /* eslint-disable no-unused-vars*/
    const { onClose, open } = props;
    const [value, setValue] = React.useState('');
    /* eslint-enable no-unused-vars*/

    const handleListItemClick = async (value) => {
      setValue(value);

      if (value !== false) {

        window.localStorage.setItem('company', JSON.stringify(value));

        let directus = await directusProject(value.company.domain, value);
        await window.localStorage.setItem('directus_data', JSON.stringify(value));
        var employee = await directus.getItems('employees', { filter: { user: { eq: value.user.id } }, fields: 'user.*.*,*.*', single: true });

        window.localStorage.setItem('directus_employee', JSON.stringify(employee.data));
        window.localStorage.setItem('directus_user', JSON.stringify(employee.data.user));

        var result = await directus.getItems('configuration');
        window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));

        window.location.href = '/';
        setIsBusy(false);


        onClose(false);

      }
      else {
        onClose(false);
      }

    };


    return (
      <Dialog onClose={() => handleListItemClick(false)} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Choose Company</DialogTitle>
        <List style={{ width: '375px' }}>
          {company !== 0 ?
            company.map((companylist, index) => (
              <ListItem key={index} button onClick={() => handleListItemClick(companylist)}>
                <ListItemText primary={companylist.company.name} />
              </ListItem>

            ))
            : ''
          }
        </List>
        <DialogActions>
          <Button onClick={() => BackButton()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const [isInitialized, setIsInitialized] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setIsBusy(false);
  };

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized, isBusy]);

  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/]}
        placeholderChar={'_'}
        showMask
      />
    );
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        // email: Yup.string()
        //   .email('Must be a valid Pin number').nullable()
        //   .max(255),
      })}
      onSubmit={form => {
        setIsBusy(true);

        let splitData = form.email.split("-");
        let splitValue = splitData[0] + "" + splitData[1] + "" + splitData[2] + "" + splitData[3] + "" + splitData[4] + "" + splitData[5];


        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ otp: String(splitValue) })
        };

        var react_app_url = process.env.REACT_APP_URL;
        if (window.location.hostname.search('interax') !== -1) {
          react_app_url = process.env.REACT_APP_URL_INTERAX;
        }

        fetch(`${react_app_url}console/custom/auth/authenticate?mode=otp`, requestOptions)
          .then(response => response.json())
          .then(async result => {
            if (result.error) {
              window.localStorage.clear();
              window.alert(result.error.message);
              setIsBusy(false);
            }
            else {

              if (result.data.length === 1) {

                window.localStorage.setItem('company', JSON.stringify(result.data[0].company));
                let directus = directusProject(result.data[0].company.domain, result.data[0]);
                window.localStorage.setItem('directus_data', JSON.stringify(result.data[0]));

                var employee = await directus.getItems('employees', { filter: { user: { eq: result.data[0].user.id } }, fields: 'user.*.*,*.*', single: true });

                window.localStorage.setItem('directus_employee', JSON.stringify(employee.data));
                window.localStorage.setItem('directus_user', JSON.stringify(employee.data.user));

                var result_config = await directus.getItems('configuration');
                window.localStorage.setItem('configuration', JSON.stringify(result_config.data[0]));

                window.location.href = '/';
                setIsBusy(false);

              }
              else {

                result.data.map(async (data, index) => {
                  company[index] = data;
                })
                handleClickOpen();

              }
            }

          })
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth variant="outlined" label="Pin Number">
            {/* <InputLabel htmlFor="formatted-text-mask-input">Pin Number</InputLabel> */}
            <OutlinedInput
              fullWidth={true}
              onChange={handleChange}
              value={values.email}
              name="email"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
            />
          </FormControl>
          <Box my={2}>
            <Button color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained">
              <Typography variant="h6">Sign in</Typography>
            </Button>
            <Button startIcon={<KeyboardBackspaceIcon />} onClick={() => props.setMode(1)}>Back</Button>
          </Box>
          <ChooseCompanyDialog open={open} onClose={handleClose} />

        </form>
      )}
    </Formik>
  );
};

export default LoginSMSEmail;

import React, { useState, useEffect } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import EventEmitter from 'src/utils/EventEmitter';
import { useStyles } from './style.js';
import Employees from './Employees';
import Suppliers from './Suppliers.js';
import Customers from './Customers.js';
import Configuration from './Configuration';
import AccountingSystems from './AccountingSystems';
import Categories from './Categories';
import useStateRef from "react-usestateref";
import directus from "../../services/directus";
import Items from './Items.js';
import CompanySettings from './CompanySettings.js';
import ApprovalLevel from './ApprovalLevel.js';
import Integration from './Integration.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Settings = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [showInvoiceTab, setShowInvoiceTab, showInvoiceTabsRef] = useStateRef(false);
  const [initialized, setInitialized] = useState(false);

  let configuration = JSON.parse(window.localStorage.getItem('configuration'));

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      getConfigurationSettings();
    }
  }, []);

  const getConfigurationSettings = async () => {
    var result = await directus.getItems('configuration');
    window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
    if (result.data.length > 0) {
      if (result.data[0]) {
        if (result.data[0].show_invoices === true) {
          setShowInvoiceTab(true);
        }
      }
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeTab(newValue);
  };

  const changeTab = (data) => {
    EventEmitter.emit('changeTab', {
      text: data
    })
  }

  return (
    <Page className={classes.root} title="Settings" style={{ paddingTop: "0px" }}>
      <Container maxWidth={false} style={{ padding: "0px" }}>
        <div className="control-pane">
          <div className="control-section">
            <AppBar position="static" className={classes.Appbar}>
              <Tabs
                variant='scrollable'
                scrollButtons='auto'
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab className={classes.customers} label="Suppliers" {...a11yProps(0)} />
                <Tab className={classes.employees} label="Employees" {...a11yProps(1)} />
                <Tab className={classes.customers} label="Customers" {...a11yProps(2)} />
                <Tab className={classes.employees} label="Configuration" {...a11yProps(3)} />
                <Tab className={classes.employees} label="Integration" {...a11yProps(4)} />
                {showInvoiceTabsRef.current && (
                  <Tab className={classes.employees} label="Accounting Systems" {...a11yProps(5)} />
                )}
                <Tab className={classes.employees} label="Categories" {...a11yProps(showInvoiceTabsRef.current ? 6 : 5)} />
                <Tab className={classes.employees} label="Items" {...a11yProps(showInvoiceTabsRef.current ? 7 : 6)} />
                <Tab className={classes.employees} label="Company Settings" {...a11yProps(showInvoiceTabsRef.current ? 8 : 7)} />
                {configuration.enable_approvals && (
                  <Tab className={classes.employees} label="Approval Level" {...a11yProps(showInvoiceTabsRef.current ? 9 : 8)} />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.tab}>
              <Suppliers supplier_settings={configuration.supplier_settings} />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tab}>
              <Employees employee_settings={configuration.employee_settings} />
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tab}>
              <Customers customer_settings={configuration.customer_settings} />
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tab}>
              <Configuration config_settings={configuration.config_settings} />
            </TabPanel>
            <TabPanel value={value} index={4} className={classes.tab}>
              <Integration config_settings={configuration.config_settings} />
            </TabPanel>
            {showInvoiceTabsRef.current && (
              <TabPanel value={value} index={5} className={classes.tab}>
                <AccountingSystems config_settings={configuration.config_settings} />
              </TabPanel>
            )}
            <TabPanel value={value} index={showInvoiceTabsRef.current ? 6 : 5} className={classes.tab}>
              <Categories config_settings={configuration.config_settings} />
            </TabPanel>
            <TabPanel value={value} index={showInvoiceTabsRef.current ? 7 : 6} className={classes.tab}>
              <Items />
            </TabPanel>
            <TabPanel value={value} index={showInvoiceTabsRef.current ? 8 : 7} className={classes.tab}>
              <CompanySettings config_settings={configuration.config_settings} />
            </TabPanel>
            {configuration.enable_approvals &&(
              <TabPanel value={value} index={showInvoiceTabsRef.current ? 9 : 8} className={classes.tab}>
                <ApprovalLevel />
              </TabPanel>
            )}
          </div>

        </div>
      </Container>
    </Page>
  );
};

export default Settings;